
// Stat Block Queries

export const stat_getAvgTime_entireCourse = async () => {
	const response = await fetch("entireCourse");
	return await response.json();
};

export const stat_getAvgTime_knowledgeChecks = async () => {
	const response = await fetch("knowledge");
	return await response.json();
};

export const stat_getAvgTime_finalAssessment = async () => {
	const response = await fetch("assessmentTime");
	return await response.json();
};

export const stat_getAvgScore_finalAssessment = async () => {
	const response = await fetch("assessmentScore");
	return await response.json();
};

export const table_getPage_completionsPerLearnerData = async () => {
	const response = await fetch("learnersCompleted");
	return await response.json();
};

export const table_getModuleData = async () => {
	const response = await fetch("module");
	return await response.json();
};

export const table_getInteractionsData = async () => {
	const response = await fetch("interactions");
	return await response.json();
};

export const table_getInternalLinksData = async () => {
	const response = await fetch("internal");
	return await response.json();
};

export const chart_getLearnersByStatus = async () => {
	const response = await fetch("learnersInProgress");
	return await response.json();
};

export const chart_getFinalAssessmentAttempts = async () => {
	const response = await fetch("final");
	return await response.json();
};

// export const chart_getFinalAssessmentCompletedCount = async () => {
// 	const response = await fetch("learnersCompleted");
// 	return await response.json();
// };

export const chart_learnersInProgressCount0 = async () => {
	const response = await fetch("learnersInProgress");
	return await response.json();
};

export const chart_learnersCompletedCount = async (): Promise<number> => {
    try {
        const response = await fetch("learnersCompleted");

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
console.log(data)
        if (!data || !Array.isArray(data.data)) {  
           throw new Error('Invalid data format');
        }

        const learners = data.data as { course_status: string }[];

        const completedCount = learners.filter((learner: { course_status: string }) => learner.course_status === 'completed').length;

        return completedCount;
    } catch (error) {
        console.error('Error fetching or processing data:', error);
        throw error;
    }
};

  
export const chart_learnersInProgressCount = async () => {
	try {
	  // Fetch the data from the API
	  const response = await fetch("learnersInProgress");
  
	  // Check if the response is ok
	  if (!response.ok) {
		throw new Error(`HTTP error! Status: ${response.status}`);
	  }
  
	  // Parse the JSON data
	  const data = await response.json();
      // Check if the data and data.data exist and are in expected format
	  if (!data || !Array.isArray(data.data)) {
		throw new Error('Invalid data format');
	  }
	  // Extract the data array
	  const learners = data.data;
  
	  // Check if the data array exists and is an array
	  if (!Array.isArray(learners)) {
		throw new Error('Invalid data format');
	  }
  
	  // Use a Set to track unique openid values
	  const uniqueOpenIds = new Set<string>();
  
	  // Iterate through the learners and add each openid to the Set
	  learners.forEach(learner => {
		const openid = learner['node.statement.actor.openid'];
		if (openid) {
		  uniqueOpenIds.add(openid);
		}
	  });
  
	  // The size of the Set is the count of unique openid values
	  const uniqueCount = uniqueOpenIds.size;
  
	  return uniqueCount;
	} catch (error) {
	  console.error('Error fetching or processing data:', error);
	  throw error; // Rethrow error to be handled by caller
	}
  };
  

  export const chart_definedInteractionsALL = async () => {
	const response = await fetch("definedInteractionsALL");
	console.log('response', response);
	return await response.json();
};
export const chart_learnerAll = async () => {
	const response = await fetch("all");
	console.log('response', response);
	return await response.json();
};