import "./App.css";
import Chart from "./components/Chart/Chart";
import Table from "./components/Table/Table";
import StatBlock from "./components/StatBlock/StatBlock";

import { studentLayout, attemptLayout, studentData, attemptData } from "./DATA";

function App() {
	return (
		<div className="App">
			<div className="headerBar">
				<img src="jnj.png" alt="" />
			</div>
			<div className="grid">
				<div className="row1">
					<Chart graphName="StudentGraph" layout={studentLayout} data={studentData} />
					<Table tableName="completions" numOfRows={13} />
					{/* Moved from Right side to see how it looks VVV */}
					<Table tableName="interactions" numOfRows={3} />
				</div>
				<div className="row2">
					<div className="statGroup">
						<StatBlock title="Average Time to Completion" statName="Entire Course" />
						<StatBlock statName="Knowledge Checks" />
						<StatBlock statName="Final Assessment" />
					</div>
					<StatBlock className="statBottom" title="Avg Score - Final Assessment" statName="*" />
				</div>
				<div className="row3">
					<Table tableName="module" numOfRows={7} />

					<Table tableName="internal" numOfRows={5} />
					<Chart graphName="AttemptsGraph" layout={attemptLayout} data={attemptData} />
				</div>
			</div>
		</div>
	);
}

export default App;
