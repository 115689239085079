import classes from "./Table.module.css";
import { useEffect, useState } from "react";
import {
	table_getPage_completionsPerLearnerData,
	table_getModuleData,
	table_getInteractionsData,
	table_getInternalLinksData,
} from "../../connections";

const Table = (Props: { tableName: string; numOfRows: number }) => {
	const [tableData, setTableData] = useState<{ [key: string]: string }[]>([{}]);
	const [tableHeaders, setTableHeader] = useState<JSX.Element[]>([]);
	const [allTableRows, setAllTableRows] = useState<JSX.Element[]>([]);
	const [tableRows, setTableRows] = useState<JSX.Element[]>([]);
	const [tableCols, setTableCols] = useState<JSX.Element[]>([]);
	const [rowsShown, setRowsShown] = useState(0);

	useEffect(() => {
		const getTableData = async () => {
			let data;
			switch (Props.tableName) {
				case "completions":
					data = await table_getPage_completionsPerLearnerData();
					setTableData(data.data);
					break;
				case "module":
					data = await table_getModuleData();
					setTableData(data.data);
					break;
				case "interactions":
					data = await table_getInteractionsData();
					setTableData(data.data);
					break;
				case "internal":
					data = await table_getInternalLinksData();
					setTableData(data.data);
					break;
			}
		};
		getTableData();
	}, []);

	useEffect(() => {
		generateTitles();
		generateRows();
		generateCols();
	}, [tableData]);

	const generateTitles = () => {
		const headings: JSX.Element[] = [];
		let isFirst: boolean = true;

		let keys: string[] = [];
		if (tableData && tableData.length > 0) {
			keys = Object.keys(tableData[0]);
		

		for (const key of keys) {
			headings.push(
				<th key={key} colSpan={1} className={isFirst ? classes.floatLeft : classes.center}>
					{key}
				</th>
			);
			isFirst = false;
		}
		}
		setTableHeader(headings);
	};

	const generateRows = () => {
		const rows: JSX.Element[] = [];
		let isFirst: boolean = true;

		let keys: string[] = [];
		if (tableData && tableData.length > 0) {
			keys = Object.keys(tableData[0]);
		

		for (const row of tableData) {
			const cells: JSX.Element[] = [];
			isFirst = true;
			for (let i = 0; i < keys.length; ++i) {
				cells.push(
					<td
						key={row[keys[i]]}
						className={`${classes.row} ${isFirst ? classes.floatLeft : classes.center}`}
						colSpan={1}
					>
						{row[keys[i]]}
					</td>
				);
				isFirst = false;
			}
			rows.push(<tr className={classes.row}>{cells}</tr>);
		}
		}
		setAllTableRows(rows);
		setTableRows(rows.slice(0, Props.numOfRows));
	};

	const generateCols = () => {
		const cols: JSX.Element[] = [];
		let keys: string[] = [];
		if (tableData && tableData.length > 0) {
			keys = Object.keys(tableData[0]);
		
		for (const key of keys) {
			cols.push(<col key={key} span={1} style={{ width: "auto" }} />);
		}
		}
		setTableCols(cols);
	};

	const nextTenRows = () => {
		setRowsShown((prev) => {
			const nextNumber = prev + Props.numOfRows;
			if (allTableRows.length < nextNumber) {
				return prev;
			}
			return nextNumber;
		});
	};

	const lastTenRows = () => {
		setRowsShown((prev) => {
			const lastNumber = prev - Props.numOfRows;
			if (lastNumber < 0) {
				return 0;
			}
			return lastNumber;
		});
	};

	useEffect(() => {
		setTableRows(allTableRows.slice(rowsShown, rowsShown + Props.numOfRows));
	}, [rowsShown]);

	return (
		<div>
			<div className={classes.tableContainer}>
				<table className={classes.table} cellSpacing="0" cellPadding="0">
					<colgroup>{tableCols}</colgroup>
					<thead className={classes.tableTitles}>
						<tr className={classes.tableHeader}>{tableHeaders}</tr>
					</thead>
					<tbody className={classes.tableBody}>{tableRows}</tbody>
				</table>
			</div>
			<div className={classes.buttonGroup}>
				<button className={classes.button} disabled={rowsShown === 0} onClick={lastTenRows}>
					{"<"}
				</button>
				<button
					className={classes.button}
					disabled={rowsShown + Props.numOfRows > allTableRows.length}
					onClick={nextTenRows}
				>
					{">"}
				</button>
			</div>
		</div>
	);
};

export default Table;
