// Student Graph
let studentTrace1: Plotly.Data = {
	x: [23],
	y: ["students"],
	name: "In Progress",
	orientation: "h",
	marker: {
		color: "rgba(220, 53, 69, .4)",
		width: 0.2,
	},
	type: "bar",
};
let studentTrace2: Plotly.Data = {
	x: [77],
	y: ["students"],
	name: "Complete",
	orientation: "h",
	type: "bar",
	marker: {
		color: "rgba(220, 53, 69, 1)",
		width: 0.2,
	},
};
export let studentData: Plotly.Data[] = [studentTrace1, studentTrace2];
// export let studentLayout: Partial<Plotly.Layout> = {
// 	title: "Total Learners, By Status",
// 	barmode: "stack",
// };

export let studentLayout: Partial<Plotly.Layout> = {
	autosize: true,
	title: { text: "Total Learners, By Status", yref: "paper", y: 1, yanchor: "bottom" },

	barmode: "stack",
	margin: {
		b: 20,
		l: 80,
		r: 20,
		t: 20,
		pad: 0,
	},
};

// Attempts Graph
let attemptTrace1: Plotly.Data = {
	x: [23],
	y: ["Passing"],
	name: "Passing Attempts",
	orientation: "h",
	marker: {
		color: "rgba(220, 53, 69, .4)",
		width: 0.2,
	},
	type: "bar",
};
let attemptTrace2: Plotly.Data = {
	x: [77],
	y: ["Total"],
	name: "Total Attempts",
	orientation: "h",
	type: "bar",
	marker: {
		color: "rgba(220, 53, 69, 1)",
		width: 0.2,
	},
};
export let attemptData: Plotly.Data[] = [attemptTrace1, attemptTrace2];
export let attemptLayout: Partial<Plotly.Layout> = {
	autosize: true,
	title: { text: "Final Assessment Attempts", yref: "paper", y: 1, yanchor: "bottom" },
	barmode: "stack",
	margin: {
		b: 20,
		l: 100,
		r: 10,
		t: 20,
		pad: 0,
	},
};
