import classes from "./Chart.module.css";
import { chart_getLearnersByStatus, chart_getFinalAssessmentAttempts } from "../../connections";
import Plot from "react-plotly.js";

import { useEffect, useState } from "react";

const Chart = (Props: {
	graphName: string;
	layout: Partial<Plotly.Layout>;
	data: Plotly.Data[];
}) => {
	// const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);

	const getChartData = async () => {
		let data;
		switch (Props.graphName) {
			case "StudentGraph":
				data = await chart_getLearnersByStatus();
				break;
			case "AttemptsGraph":
				data = await chart_getFinalAssessmentAttempts();
				break;
		}
		// setPlotlyData(data);
	};

	useEffect(() => {
		// getChartData();
		// plotly.newPlot(Props.graphName, Props.data, Props.layout);
	}, []);

	return (
		<Plot
			config={{ responsive: true, displayModeBar: false }}
			data={Props.data}
			layout={Props.layout}
			className={classes.chart}
		/>
	);
};

export default Chart;
